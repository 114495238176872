<template>
  <footer>
    <div class="ml-5 md:ml-12 mb-2 text-white" v-if="showFooterDetail">
      <img src="@/assets/mastermind-white-logo.svg" alt="Mastermind logo" class="w-48 h-auto pt-4" />
      <p class="mt-5">Mastermind Cognitive Training</p>
      <address>
        <p>1320 Illinois Rte 59</p>
        <p>Naperville, IL</p>
        <p>60563</p>
      </address>
      <div class="mt-2 md:hidden">
        <a
          :href="label.link"
          target="_blank"
          class="mt-3 underline block"
          v-for="label in labels"
          :key="label.title"
          >{{ label.title }}</a
        >
      </div>
    </div>
    <div class="flex justify-between p-4 text-white">
      <div>
        <em
          v-if="!showFooterDetail"
          @click="changeFooterVisibility()"
          class="fa-solid fa-chevron-up md:hidden"
        ></em>
        <em
          v-else
          @click="changeFooterVisibility()"
          class="fa-solid fa-chevron-down md:hidden"
        ></em>
        <em class="ml-5 fa-regular fa-copyright"></em>
        <span class="ml-2 text-sm md:text-base"
          >{{ new Date().getFullYear() }} Mastermind Cognitive Training. All rights reserved.</span
        >
      </div>
      <div class="hidden md:block mr-5">
        <a
          :href="label.link"
          target="_blank"
          class="mx-4 underline"
          v-for="label in labels"
          :key="label.title"
          >{{ label.title }}</a
        >
        <em
          v-if="!showFooterDetail"
          @click="changeFooterVisibility()"
          class="mx-2 fa-solid fa-chevron-up"
        ></em>
        <em
          v-else
          @click="changeFooterVisibility()"
          class="mx-2 fa-solid fa-chevron-down"
        ></em>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from "vue";

const showFooterDetail = ref(false);
const labels = ref([
  {
    title: "Contact Us",
    link: "https://www.mastermindsports.com/contact-us",
  },
  {
    title: "Privacy Policy",
    link: "https://www.mastermindsports.com/privacy-policy",
  },
  {
    title: "Terms of Service",
    link: "https://mastermindtraining.com/terms-of-service",
  },
]);

function changeFooterVisibility() {
  showFooterDetail.value = !showFooterDetail.value;
}
</script>

<style scoped>
footer {
  background-color: #111c2e;
}
</style>
